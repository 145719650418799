import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const hello = `    _   ___ _____ ___ ___ ___<br/>
  /_\\ | _ \\_   _| __/ _ \\_ /<br/>
 / _ \\|  _/ | | |__ \\_  /_ \\<br/>
/_/ \\_\\_|   |_| |___//_/\\__/<br/>
`;

const EMPTY_LINE = { hasPrompt: true, editable: true, content: '' };
const LOADING = 'status.LOADING';
const COMPLETED = 'status.COMPLETED';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const prompt = <span className="mr-2 text-light-green-500">[root@apt593.ninja /]$</span>;
const loadingStatus = [
  '[0.51252] sd 0:0:0:0: [sda] Assuming drive cache: write through',
  '/dev/sda/ recovering journal,/dev/sda/ clean, 356433/1256640 files, 2184072/5016832 blocks',
  '[<span class="text-red-500">-</span>] Failed to start Open Vulnerability Assessment System Manager Daemon',
  '[+] Executing exploit',
  '[+] Bypassing user authentication',
  '[+] Logged in as user',
  '[+] Escalating privileges',
  '[+] Logged in as root',
];

const HackingConsole = () => {
  const inputRef = useRef(null);
  const linesRef = useRef(null);
  const [lines, setLines] = useState([EMPTY_LINE]);
  const [viewLine, setViewLine] = useState(0);
  const [status, setStatus] = useState(LOADING);
  const [currentLoadingLines, setCurrentLoadingLines] = useState(0);

  useEffect(() => {
    let timeout = null;
    if (status === LOADING && currentLoadingLines < loadingStatus.length) {
      timeout = setTimeout(() => {
        setCurrentLoadingLines(currentLoadingLines + 1);
      }, 200 + Math.random() * 1000);
    } else {
      timeout = setTimeout(() => {
        setStatus(COMPLETED);
      }, 1000 + Math.random() * 1000);
    }
    return () => clearTimeout(timeout);
  }, [status, currentLoadingLines]);

  useEffect(() => {
    if (status === COMPLETED && inputRef.current) {
      inputRef.current.focus();
    }
  }, [status, lines]);

  // useEffect(() => {
  // }, [lines, viewLine]);

  // Clear first line
  const clearFirstLine = () => {
    linesRef.current.querySelector('span[role="textbox"]').textContent = '';
  };

  const disableLines = lines => [...lines.map(l => ({ ...l, editable: false }))];
  const createOutput = text =>
    text
      .split('\n')
      .map(content => ({ hasPrompt: false, editable: false, content: content.replace(/ (?![^<]*>)/g, '&nbsp') }));

  const runCommand = command => {
    const currentDate = new Date();
    let newLines = [EMPTY_LINE];
    let isClear = false;
    switch (command) {
      case 'clear':
        isClear = true;
        break;
      case 'help':
        newLines = [
          ...disableLines(lines),
          ...createOutput(`
<b>Available commands:</b>
<br/>
<b>clear</b>  --   Clear the terminal screen.
<b>ls</b>     --   List directory contents.
<b>cat</b>    --   Concatenate and print files.`),
        ];
        break;
      case 'ls':
        newLines = [
          ...disableLines(lines),
          ...createOutput(
            `-rw-r--r--  root  root  32 ${
              months[currentDate.getMonth()]
            } ${currentDate.getDate()} ${currentDate.getFullYear()} flag.txt`
          ),
        ];
        break;
      case 'cat flag.txt':
        newLines = [
          ...disableLines(lines),
          ...createOutput(
            `!Felicitaciones!
APT{3nC0n7r4573_Nu357r0_fl4G}
<br/>
Escríbenos: <a href="mailto:admin@apt593.ninja">admin@apt593.ninja</a>`
          ),
        ];
        break;
      default:
        newLines = [...disableLines(lines)];
        break;
    }
    if (isClear) {
      setLines(newLines);
      setViewLine(newLines.length);
      clearFirstLine();
    } else {
      const lastLine = newLines[newLines.length - 1];
      setLines([
        ...newLines.slice(0, newLines.length - 1),
        {
          ...lastLine,
          content: lastLine.editable ? command : lastLine.content,
        },
        EMPTY_LINE,
      ]);
      setViewLine(newLines.length);
    }
  };

  const keyPressListener = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      runCommand(inputRef.current.textContent);
      return false;
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (0 < viewLine) {
        setViewLine(viewLine - 1);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (viewLine < lines.length - 1) {
        setViewLine(viewLine + 1);
      }
    } else if (e.key === 'Tab') {
      e.preventDefault();
    }
  };

  return (
    <div className="bg-gray-900 w-full max-w-3xl  mx-auto text-sm  border border-light-green-600 shadow-xl">
      <div className="h-6 bg-light-green-600 w-full flex justify-between items-center px-3">
        <span className="text-black text-sm font-mono">APT593 - Hacking Terminal</span>
        <span className="text-black space-x-2">
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
      <code className="text-light-green-100 p-2 block overflow-auto h-64 border-t-8 border-b-8 border-transparent">
        {status === LOADING && (
          <ul className="list-none m-0 p-0 text-light-green-600">
            <li>Loading, please wait...</li>
            {loadingStatus.slice(0, currentLoadingLines).map((status, statusIndex) => (
              <li key={statusIndex} dangerouslySetInnerHTML={{ __html: status }} />
            ))}
          </ul>
        )}
        {status === COMPLETED && (
          <>
            <div
              className="tracking-widest leading-none mb-2"
              dangerouslySetInnerHTML={{ __html: hello.replace(/\s/g, '&nbsp;') }}
            />
            <ul className="list-none m-0 p-0" ref={linesRef}>
              {lines.map((l, lineIndex) => (
                <li key={`${l}-${lineIndex}`}>
                  <div
                    className="outline-none"
                    tabIndex={0}
                    role="textbox"
                    onKeyPress={() => {}}
                    onClick={() => inputRef.current.focus()}
                  >
                    {l.hasPrompt && prompt}
                    <span
                      role="textbox"
                      tabIndex={0}
                      onKeyDown={keyPressListener}
                      ref={l.editable ? inputRef : null}
                      className="outline-none"
                      contentEditable={l.editable}
                      dangerouslySetInnerHTML={{ __html: l.content }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </code>
    </div>
  );
};

export default HackingConsole;

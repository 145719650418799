import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { ThemeContext } from '../utils';
import HackingConsole from '../components/hackingConsole';

const Content = () => {
  const [theme] = useContext(ThemeContext);
  return (
    <div className="flex-1 flex flex-col items-center justify-center">
      <h1 className={`text-6xl font-mono ${theme.text}`}>404</h1>
      <div className="p-4 w-full">
        <HackingConsole />
      </div>
    </div>
  );
};

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: No encontrado" />
      <Content />
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
